'use strict';


if ($("#reservoirApplication").length > 0) {
    $('.reservoirApplication').on('click', function (e) {
        $(".reservoirApplication").toggleClass("active");
        $('.sidebar-expand-menu').toggleClass("active"); //you can list several class names 
        e.preventDefault();
    });
}


$('#selectWard, #pageSearch').selectize({
    sortField: 'text'
});

if ($(window).width() < 990) {
    $('#containerDataMap').removeClass("right-datatab-active");
    $('.toggleRightDataTab').on('click', function (e) {
        $('#containerDataMap').toggleClass("right-datatab-active"); //you can list several class names 
        e.preventDefault();
    });

} else {
    $('#containerDataMap').addClass("right-datatab-active");
    $('.toggleRightDataTab').on('click', function (e) {
        $('#containerDataMap').toggleClass("right-datatab-active"); //you can list several class names 
        e.preventDefault();
    });

}


function createChart(id) {

    Highcharts.chart(id, {

        chart: {
            backgroundColor: {
                linearGradient: [0, 0, 500, 500],
                stops: [
                    [0, 'rgb(52, 58, 64)'],
                    [1, 'rgb(52, 58, 64)']
                ]
            },
            marginTop: 10,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: ['Jun-2019', 'Jul-2019', 'Aug-2019', 'Sep-2019', 'Oct-2019', 'Nov-2019', 'Dec-2019', 'Jan-2019', 'Feb-2019', 'Mar-2019', 'Apr-2019', 'May-2019'],
            crosshair: true,
            labels: {
                style: {
                    color: '#99A9BE',
                }
            },
        },
        colors: ['#1E88E5', '#FF5722'],

        yAxis: {

            gridLineWidth: 0,
            min: 0,
            labels: {
                style: {
                    color: '#99A9BE',
                }
            },
            title: {
                text: 'TMC',
                style: {
                    color: '#99A9BE',
                }
            }
        },

        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },

        credits: {
            enabled: false
        },
        legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'top',
            itemStyle: {
                color: 'white'
            },
            itemHoverStyle: {
                color: 'white'
            }
        },

        series: [{
                legendIndex: 3,

                type: 'column',
                name: 'Storage (TMC)',
                data: [188, 287, 243, 183, 323, 274, 295, 384, 187, 168, 244, 254]
            },
            {
                type: 'spline',
                name: 'Water Lifted (TMC)',
                legendIndex: 0,
                data: [88, 267, 203, 133, 223, 254, 265, 354, 147, 158, 224, 214],
                index: 5,
                marker: {
                    lineWidth: 1,
                    lineColor: Highcharts.getOptions().colors[1],
                    fillColor: 'white'
                }
            }
        ]
    });
}





function createChart01(id) {

    Highcharts.chart(id, {

        chart: {
            backgroundColor: {
                linearGradient: [0, 0, 500, 500],
                stops: [
                    [0, 'rgb(52, 58, 64)'],
                    [1, 'rgb(52, 58, 64)']
                ]
            },
            marginTop: 10,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: ['Jun-2019', 'Jul-2019', 'Aug-2019', 'Sep-2019', 'Oct-2019', 'Nov-2019', 'Dec-2019', 'Jan-2019', 'Feb-2019', 'Mar-2019', 'Apr-2019', 'May-2019'],
            crosshair: true,
            labels: {
                style: {
                    color: '#99A9BE',
                }
            },
        },
        colors: ['#1E88E5', '#FF5722', '#ff2020'],

        yAxis: {

            gridLineWidth: 0,
            min: 0,
            labels: {
                style: {
                    color: '#99A9BE',
                }
            },
            title: {
                text: 'TMC',
                style: {
                    color: '#99A9BE',
                }
            }
        },

        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },

        credits: {
            enabled: false
        },
        legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'top',
            itemStyle: {
                color: 'white'
            },
            itemHoverStyle: {
                color: 'white'
            }
        },

        series: [{
                legendIndex: 1,
                type: 'column',
                name: 'Storage (TMC)',
                data: [188, 287, 243, 183, 323, 274, 295, 384, 187, 168, 244, 254]
            },
            {
                type: 'spline',
                name: 'Water Lifted (TMC)',
                legendIndex: 2,
                data: [88, 267, 203, 133, 223, 254, 265, 354, 147, 158, 224, 214],
                index: 5,
                marker: {
                    lineWidth: 1,
                    lineColor: Highcharts.getOptions().colors[1],
                    fillColor: 'white'
                }
            },
            {
                type: 'spline',
                name: 'FRL',
                legendIndex: 3,
                data: [485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485],
                index: 5,
                marker: {
                    lineWidth: 1,
                    lineColor: Highcharts.getOptions().colors[1],
                    fillColor: 'white'
                }
            }
        ]
    });
}

$('.heatmapEnable').on('click', function (e) {
    $(this).toggleClass('active');
    $('.map-image').toggleClass('heatmapbg');

    e.preventDefault();
});

// $("#ph-motor-icon-7 #icon-7 ").addClass('rotating');

$(".informationSummaryDataTab").show();
$(".rainfallSummaryDataTab,.supplySummaryDataTab,.demandSummaryDataTab,.indentsRaisingDataTab,.waterReleaseDataTab,.kpisDataTab").hide();

$('#informationSummaryData').on('click', function (e) {
    $("#leftMenuContainer li a").removeClass("active");
    $(this).addClass('active');
    $(".informationSummaryDataTab").show();
    $(".rainfallSummaryDataTab,.supplySummaryDataTab,.demandSummaryDataTab,.indentsRaisingDataTab,.waterReleaseDataTab,.kpisDataTab").hide();
    $('#leftMenuContainer .collapse').collapse('hide');
    e.preventDefault();
});


$('#rainfallSummaryData, .linkforrainfallDatatab').on('click', function (e) {
    $("#leftMenuContainer li a").removeClass("active");
    $("#rainfallSummaryData").addClass('active');
    $(".informationSummaryDataTab,.supplySummaryDataTab,.demandSummaryDataTab,.indentsRaisingDataTab,.waterReleaseDataTab,.kpisDataTab").hide();
    $(".rainfallSummaryDataTab").show();
    e.preventDefault();
});
$('#supplySummaryData').on('click', function (e) {
    $("#leftMenuContainer li a").removeClass("active");
    $(this).addClass('active');
    $(".informationSummaryDataTab,.rainfallSummaryDataTab,.demandSummaryDataTab,.indentsRaisingDataTab,.waterReleaseDataTab,.kpisDataTab").hide();
    $(".supplySummaryDataTab").show();
    e.preventDefault();
});
$('#demandSummaryData').on('click', function (e) {
    $("#leftMenuContainer li a").removeClass("active");
    $(this).addClass('active');
    $(".informationSummaryDataTab,.supplySummaryDataTab,.rainfallSummaryDataTab,.indentsRaisingDataTab,.waterReleaseDataTab,.kpisDataTab").hide();
    $(".demandSummaryDataTab").show();
    e.preventDefault();
});
$('#indentsRaisingData').on('click', function (e) {
    $("#leftMenuContainer li a").removeClass("active");
    $(this).addClass('active');
    $(".informationSummaryDataTab,.supplySummaryDataTab,.demandSummaryDataTab,.rainfallSummaryDataTab,.waterReleaseDataTab,.kpisDataTab").hide();
    $(".indentsRaisingDataTab").show();
    $('#leftMenuContainer .collapse').collapse('hide');
    e.preventDefault();
});
$('#waterReleaseData').on('click', function (e) {
    $("#leftMenuContainer li a").removeClass("active");
    $(this).addClass('active');
    $(".informationSummaryDataTab,.supplySummaryDataTab,.demandSummaryDataTab,.indentsRaisingDataTab,.rainfallSummaryDataTab,.kpisDataTab").hide();
    $(".waterReleaseDataTab").show();
    e.preventDefault();
});

$('#kpisData').on('click', function (e) {
    $("#leftMenuContainer li a").removeClass("active");
    $(this).addClass('active');
    $(".informationSummaryDataTab,.supplySummaryDataTab,.demandSummaryDataTab,.indentsRaisingDataTab,.rainfallSummaryDataTab,.waterReleaseDataTab").hide();
    $(".kpisDataTab").show();
    $('#leftMenuContainer .collapse').collapse('hide');
    e.preventDefault();
});

// $(this).addClass('active');
//     $("#informationSummaryData").removeClass("active");
//     $("#supplySummaryData").removeClass("active");
//     $("#demandSummaryData").removeClass("active");
//     $("#indentsRaisingData").removeClass("active");
//     $("#waterReleaseData").removeClass("active");
//     $("#kpisData").removeClass("active");





$("#rsvr-srr-shape").addClass('dead-storage-animation');
$("#rsvr-annapurna-shape, #rsvr-sri-komaravelli-mallanna-shape").addClass('dead-storage-animation01');
$("#ph-animated-lakshmi, #ph-animated-saraswati, #ph-animated-parvathi, #ph-animated-gayathri, #resevoir-srr-outflow").addClass('animatated-path');
$("#Path_129, #Path_81").addClass('river-path');
// $("#path-river-kaddam").addClass('path-river-right-animation');
// $(" #path-river-srsp-kaddam, #path-river-srsp").addClass('path-river-left-animation');

$('.btn-start-animation').on('click', function (e) {
    $("#ph-animated-lakshmi, #ph-animated-saraswati, #ph-animated-parvathi, #ph-animated-gayathri").toggleClass('path-right-animation');
    $("#ph-gayathri-motor-icon, #ph-parvathi-motor-icon, #ph-saraswati-motor-icon, #ph-laksmi--motor-icon").toggleClass('pump-running');
    $("#path-river-kaddam, #path-river-ck-outflow-02, #path-river-venkatraopet-flow-01, #path-river-ashti-flow, #path-river-venkatraopet-ashti-flow, #path-river-gudem-flow, #path-river-gudem-peddavagu-flow, #path-river-pranahitha-flow, #path-river-tekra-flow, #path-river-ck-inflow, #Path_2355,  #ph-akkaram-flow-01, #path-river-wardha, #ph-akkaram-flow-02, #ph-markook-flow, #path-river-lb-outflow-03, #path-river-pathagudem-flow, #path-river-lb-outflow-02, #path-river-perur-flow, #path-river-eturunagaram-flow, #path-river-bhadrachalam-flow").toggleClass('path-river-right-animation animatated-river-path');
    $("#path-river-srsp-kaddam, #path-river-srsp, #path-river-parvathi-inflow, #path-river-saraswati-inflow-01, #path-river-saraswati-outflow, #resevoir-srr-outflow, #ph-annapurna-flow, #resevoir-ranganayaka-outflow, #ph-mallanna-sagar-flow-02, #ph-mallanna-sagar-flow-01, #canal-mallanna-sagar-outflow-01, #ph-mallana-sagar-bypas-canal, #mallana-sagar-bypas-canal, #path-river-indravathi, #path-river-lb-outflow-01, #path-river-saraswati-inflow-02, #Path_135_anim,  #ph-animated-us-gayathri, #ph-animated-ds-gayathri").toggleClass('path-river-left-animation animatated-river-path');
    e.preventDefault();
});

$("#ph-mukpal,#ph-gayathri,#ph-annapurna,#ph-rampur,#ph-rajeshwaraopet,#ph-ranganayaka-sagar,#ph-mallanna-sagar,#ph-laksmi,#ph-saraswati,#ph-parvathi,#ph-nandi,#ph-ks-markook,#ph-ks-akkaram").popover({
    html: true,
    title: "Laxmi Pump House",
    trigger: 'hover',
    content: function () {
        // var content = $(this).attr("data-popover-content");
        var content = document.getElementById('pumpHouse');
        return $(content).children(".popover-body").html();
    },
    placement: function (context, src) {
        $(context).addClass('popover-sort-details');
        return 'auto'; // - 'top' placement in my case
    }
});


$("#rsvrb-sadarmat,#rsvrb-thupakuladuem,#rsvrb-sriram-project,#rsvr-nizam-sagar-project,#rsvr-singur, #rsvrb-sadarmat-barraige, #rsvr-kanrp, #rsvrb-sri-pada-yellampally, #rsvrb-parvathi, #rsvrb-saraswati, #rsvrb-lakshmi, #rsvrb-tummidihetti, #rsvr-nandi, #rsvr-srr, #rsvr-lower-manair, #rsvr-annapurna, #rsvr-sri-ranga-nayak-sagar, #rsvr-sri-komaravelli-mallanna, #rsvr-konda-pochamma, #rsvr-gandhamala, #rsvr-baswapur").popover({
    html: true,
    title: "Sri Pada Yellampally",
    trigger: 'hover',
    content: function () {
        // var content = $(this).attr("data-popover-content");
        var content = document.getElementById('reservoirDetails');
        return $(content).children(".popover-body").html();
    },
    placement: function (context, src) {
        $(context).addClass('popover-sort-details');
        return 'auto'; // - 'top' placement in my case
    }
});

$("#Path_2945, #Path_2969, #Path_3292, #Path_3296, #Path_3298, #Path_3300, #Path_3302, #Path_3304, #Path_3306, #Path_3308, #Path_3310, #Path_3312, #Path_3314, #Path_3316, #Path_3318, #Path_3320, #Path_3322, #Path_3324,  #Path_3326, #Path_3328, #Path_3330, #Path_3332, #Path_3334, #Path_3336, #Path_3338, #Path_3340, #Path_3342, #Path_3344, #Path_3346, #Path_3348, #Path_3350, #Path_3352, #Path_3354, #Path_3356, #Path_3358, #Path_3360, #Path_3362, #Path_3364, #Path_3366").popover({
    html: true,
    title: "D/52 Offtake Point",
    trigger: 'hover',
    content: function () {
        // var content = $(this).attr("data-popover-content");
        var content = document.getElementById('offtakepointDetails');
        return $(content).children(".popover-body").html();
    },
    placement: function (context, src) {
        $(context).addClass('popover-sort-details');
        return 'auto'; // - 'top' placement in my case
    }
});

$("#river-penganga-gomutri,#river-pathagudem,#river-perur,#river-eturunagaram,#river-bhadrachalam,#river-kaleswaram-godavari-bridge,#river-godavari,#river-indurthi-maneru, #river-katterasala,#river-chennur,#river-peddavagu, #river-pranahitha-1,#river-pranahitha-2, #river-wardha, #river-tekra, #rsvr-singur").popover({
    html: true,
    title: "Wardha",
    trigger: 'hover',
    content: function () {
        // var content = $(this).attr("data-popover-content");
        var content = document.getElementById('riverPoints');
        return $(content).children(".popover-body").html();
    },
    placement: function (context, src) {
        $(context).addClass('popover-sort-details');
        return 'auto'; // - 'top' placement in my case
    }
});
// .popover('show');



if ($("#containerChart").length > 0) {
    createChart('containerChart');
}
if ($("#containerChart01").length > 0) {
    createChart01('containerChart01');
}
if ($("#containerChart02").length > 0) {
    createChart('containerChart02');
}
if ($("#containerChart03").length > 0) {
    createChart01('containerChart03');
}

$('.reservoirToggleContent').hide();
$('.pumpHouseToggleContent').hide();
$('.reservoirBarrageToggleContent').hide();
$('.backToAllSystems').hide();

$("g[id*='rsvr-']").click(function () {
    console.log("working");
    $('.allSystemsToggleContent').hide();
    $('.reservoirBarrageToggleContent').hide();
    $('.reservoirToggleContent').show();
    $('.pumpHouseToggleContent').hide();
    $('.backToAllSystems').show();
    $('#containerDataMap').addClass('right-datatab-active');

});
$("g[id*='ph-']").click(function () {
    console.log("working");
    $('.allSystemsToggleContent').hide();
    $('.reservoirBarrageToggleContent').hide();
    $('.reservoirToggleContent').hide();
    $('.demandToggleContent').hide();     
    $('.pumpHouseToggleContent').show();
    $('.backToAllSystems').show();
    $('#containerDataMap').addClass('right-datatab-active');
});
$("g[id*='rsvrb-']").click(function () {
    console.log("working");
    $('.allSystemsToggleContent').hide();
    $('.reservoirBarrageToggleContent').show();
    $('.reservoirToggleContent').hide();
    $('.pumpHouseToggleContent').hide();
    $('.backToAllSystems').show();
    $('#containerDataMap').addClass('right-datatab-active');
});
$("g[id*='rv-']").click(function () {
    console.log("working");
    $('.allSystemsToggleContent').hide();
    $('.reservoirToggleContent').show();
    $('.pumpHouseToggleContent').hide();
    $('.demandToggleContent').hide();    
    $('.backToAllSystems').hide();
    $('#containerDataMap').addClass('right-datatab-active');
});
$("g[id*='dmd-']").click(function () {
    console.log("working");
    $('.allSystemsToggleContent').hide();
    $('.reservoirToggleContent').hide();
    $('.pumpHouseToggleContent').hide();
    $('.demandToggleContent').show();    
    $('.backToAllSystems').hide();
    $('#containerDataMap').addClass('right-datatab-active');
});
$("g[id*='dmd-']").click(function () {
    console.log("working");
    $('.demandToggleContent').show();
    $('.reservoirToggleContent').hide();
    $('.pumpHouseToggleContent').hide();
    $('.backToAllSystems').hide();
    $('#containerDataMap').addClass('right-datatab-active');
});
$(".backToAllSystems").click(function () {
    console.log("working");
    $('.allSystemsToggleContent').show();
    $('.reservoirBarrageToggleContent').hide();
    $('.reservoirToggleContent').hide();
    $('.pumpHouseToggleContent').hide();
    $('.backToAllSystems').hide();
});

$(document).ready(function () {
    $(".marquee").marquee({
        gap: 50,
        duration: 10000,
        delayBeforeStart: 0,
        direction: 'left',
        duplicated: true
    });
});